@font-face {
  font-family: "ClashDisplay-Bold";
  src: url("./fonts/ClashDisplay-Bold.woff2") format("woff2"),
    url("./fonts/ClashDisplay-Bold.woff") format("woff"),
    url("./fonts/ClashDisplay-Bold.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

* {
  font-family: "ClashDisplay-Bold";
}

.navbar {
  transition: transform 0.3s ease-in-out;
}

.navbar--visible {
  transform: translateY(0);
}

.navbar--hidden {
  transform: translateY(-100%);
}

.pointer {
  cursor: pointer;
}

.active {
  color: #a855f7;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-modal-footer {
  display: none;
}

.ant-select-selector {
  height: 50px;
}

.ant-select-selection-placeholder,
.ant.-select-selection-item {
  margin-top: 5px;
}

.slide-in {
  transform: translateX(100%);
  animation: slide-in 0.5s ease forwards;
}

@keyframes slide-in {
  to {
    transform: translateX(0);
  }
}

.custom-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  width: 100%;
  padding: 20px;
  background-color: #1a1a1a;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.custom-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
}

.form-label {
  margin-bottom: 8px;
  color: #ffffff;
}

.form-input {
  padding: 10px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: #ff6b6b;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
}

.form-button:hover {
  background-color: #ff5252;
}

.form-message {
  margin-top: 16px;
  color: #ffffff;
}

